import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We are so excited to welcome Will Hall at this year’s SuperBot that we couldn’t wait until March 31st to pick his brain about design and his thoughts on the voice ecosystem. See what wisdom Will had to share with us.`}</p>
    <h3>{`Tell us a little about yourself`}</h3>
    <p>{`I’m the Chief Creative Officer here at `}<a parentName="p" {...{
        "href": "https://rain.agency/"
      }}>{`RAIN`}</a>{`, a digital innovation company that’s been focusing on voice and conversational AI over the past several years. I’m also an adjunct professor for design and technology at NYU.`}</p>
    <h3>{`When did you first get interested in designing conversational interfaces?`}</h3>
    <p>{`We realized several years back that interacting with a physical device through voice was going to be a thing. We worked with fitness companies that made high end treadmills, and that had a natural use case for voice. If you had music playing while you were running, I thought it would be great if you could change the song hands-free without picking up your phone. We’ve kept an eye out on that technology and when Amazon Alexa launched, we actually came out with the first branded skill on their platform. Voice is the most human-centered technology that’s ever existed, so it was sort of an inevitability from our perspective. I think the market’s starting to prove that to be correct.`}</p>
    <h3>{`What goals do brands come to RAIN to accomplish?`}</h3>
    <p>{`epending on the use case, we could be measuring anything from the amount of product sold to data collection. A customer interacting with our experience allows us to gather their data with their permission. From that, we start to understand attitudinal things and it becomes easier to form a relationship with your customer. It’s different for each brand, but common goals usually look like lead generation and creating recurring revenue.`}</p>
    <p>{`Some brands want to create overhead efficiencies. To use hospitality businesses as an example, we realized that the majority of calls to the front desk were lower level tasks that a chatbot could handle. Instead of the front desk being bottlenecked by wakeup calls and towel requests, they could focus more on being hospitable and the guests could get their requests fulfilled even faster thought a chatbot.`}</p>
    <p>{`A brand might also come to us with an idea for a voice skill. At RAIN, we look for an overlap of business drivers, consumer truths, and a digital ecosystem. With that overlap, we develop a product that’s going to be strategic and notable. Sometimes that looks different than what the brand originally wanted, but we always design with their goals in mind.`}</p>
    <h3>{`What do you anticipate happening to the voice ecosystem in the next few years?`}</h3>
    <p>{`One of the trends I hope to see is the use of voice as assistant technology to remove the stuff I see bogging down great human potential. That will allow us to do what we truly do well.`}</p>
    <p>{`If we look at the human ability to deal with complexity, our evolution has been very slow. Our challenge with that is our digital existences are becoming more complex exponentially. Dealing with that complexity is a huge problem, and I think the only way we can bridge that gap is with an assistive layer. At the enterprise level, even executives spend a lot of time doing lower level tasks. As CCO, I should be thinking about moving our brand forward and not worrying about scheduling calls.`}</p>
    <h3>{`Do you have any tips for voice developers?`}</h3>
    <p>{`The thing we really hang our hat on at RAIN is the word “ecosystem.” I think it’s vital to think about these voice experiences as an ecosystem and not a one-off, siloed thing for brands. Create your voice experience to be a part of everything else going on so that everything can work together. All the examples I can think of where we had real success was because we were building this interconnected system as much as we were building a voice skill or action. I don’t think most folks are really addressing that challenge.`}</p>
    <hr></hr>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://rain.agency/"
        }}>{`RAIN`}</a>{` is a pioneer in voice technology and conversational AI. Their mission is to help brands innovate at the intersection of marketing and technology by defining voice strategies, designing conversational experiences, and building voice technology software. Their clients include Campbell Soup Company, Tide, and Sesame Street, to name just a few.`}</em>{` `}<em parentName="p">{`You can find Will at this year’s SuperBot, where he will be speaking.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      